import { FeatureAccessMap } from "@smartrr/shared/entities/AccountPlan";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { mapValues, values } from "lodash";

export interface IAccountFeatures {
  id: string;
  label: string;
  description: string;
  selected: boolean;
}

export const formatDateTime = (date: ISODateString) => {
  const day = ISODateString.fromString(date);
  const formattedDay = day.toLocaleString({
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  const time = day.toLocaleString({
    hour: "2-digit",
    minute: "2-digit",
  });
  return [formattedDay, time];
};

export const getAccountFeatures = (features: FeatureAccessMap) => {
  return values(
    mapValues(features, (selected, label) => {
      switch (label) {
        case "ANALYTICS": {
          return {
            id: label,
            label: "Analytics",
            description: 'This controls access to the "Reports" (formerly "Analytics") nav item in the app',
            selected,
          };
        }
        case "CORE_CONFIGURATION": {
          return {
            id: label,
            label: "Core Configuration",
            description: "This should be enabled for every merchant, except those who have yet to choose a plan",
            selected,
          };
        }
        case "CORE_SUBSCRIPTIONS": {
          return {
            id: label,
            label: "Core Subscriptions",
            description:
              'This controls access to the "Subscriptions" and "Subscription Programs" nav items in the app. This should be enabled for every merchant, but can be disabled if a merchant has not agreed to billing',
            selected,
          };
        }
        case "INTEGRATIONS": {
          return {
            id: label,
            label: "Integrations",
            description: 'This controls access to the "Integrations" nav item in the app',
            selected,
          };
        }
        case "PREPAID_SELLING_PLAN": {
          return {
            id: label,
            label: "Prepaid Subscription Plans",
            description:
              'This controls access to the prepaid options in the "Subscription Programs" page in the app',
            selected,
          };
        }
        case "RETENTION": {
          return {
            id: label,
            label: "Retention",
            description: 'This controls access to the "Retention" nav item in the app',
            selected,
          };
        }
        case "SEQUENTIAL_SELLING_PLAN": {
          return {
            id: label,
            label: "Sequential Subscription Plans",
            description:
              'This controls access to the basic sequential options in the "Subscription Programs" page in the app',
            selected,
          };
        }
        case "LOOKER_ANALYTICS": {
          return {
            id: label,
            label: "Looker Analytics",
            description: 'This controls access to the "Advanced Analytics" nav item in the app',
            selected,
          };
        }
        case "LOYALTY": {
          return {
            id: label,
            label: "Loyalty",
            description: 'This controls access to the "Loyalty" nav item in the app',
            selected,
          };
        }
        case "BUNDLES": {
          return {
            id: label,
            label: "Bundles",
            description: 'This controls access to the "Bundles" nav item in the app',
            selected,
          };
        }
        // * Hiding this flag
        // case "SHOPIFY_DEBUG":
        //   return {
        //     id: label,
        //     label: "Shopify Debug",
        //     description:
        //       "This controls access to the super user admin panel and allows merchants to view it. This should be disabled for all merchants.",
        //     selected,
        //   };
        case "ADVANCED_SEQUENTIAL_SELLING_PLAN": {
          return {
            id: label,
            label: "Advanced Sequential Subscription Plans",
            description:
              'This controls access to the advanced sequential options in the "Subscription Programs" page in the app',
            selected,
          };
        }
        // * Hiding this flag
        // case "SETUP":
        //   return {
        //     id: label,
        //     label: "Setup",
        //     description: 'This controls access to the "Setup" nav item in the app',
        //     selected,
        //   };
        case "TRANSLATIONS": {
          return {
            id: label,
            label: "Translations",
            description: 'This controls access to the "Translations" nav item in the app',
            selected,
          };
        }
        case "PASSWORDLESS": {
          return {
            id: label,
            label: "Passwordless",
            description: 'This controls access to the "Customer login" section in Theming > Advanced',
            selected,
          };
        }
        case "TRENDING": {
          return {
            id: label,
            label: "Trending",
            description:
              'This controls access to the "Trending Lists" tab in the "Subscription Programs" page in the app',
            selected,
          };
        }

        default: {
          return {};
        }
      }
    }) as IAccountFeatures[]
  );
};
